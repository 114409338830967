import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"
import Avatar from "./Avatar"
import { useTheme } from "../utils/hooks"
import Icon from "./Icon"
import { Link } from "gatsby"

const Container = styled.div`
  padding: 10px;
  margin-bottom: ${p => p.theme.margin * 3}px;
  p {
    margin-bottom: ${p => p.theme.margin}px;
  }
  /* border: 1px solid ${p => p.theme.colors.border}; */
`

const Row = styled.div`
  display: flex;
  /* align-items: center; */
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const IconLink = styled.a`
  display: block;
  margin-right: 10px;
  transition: all 0.3s;
  color: ${p => p.theme.colors.textSecondary};
  &:hover {
    color: ${p => p.theme.colors.accent};
  }
`

const TimeSince = ({ start }) => {
  const calcYearDecimal = useCallback(() => {
    const day = 1000 * 60 * 60 * 24
    const year = 365.2425 * day
    const diff = new Date().getTime() - start.getTime()
    return diff / year
  }, [start])
  const [time, setTime] = useState(calcYearDecimal())
  useEffect(() => {
    const tick = setInterval(() => {
      setTime(calcYearDecimal())
    }, 500)
    return () => clearInterval(tick)
  }, [calcYearDecimal])
  return <span>{time.toFixed(7)}</span>
}

const Profile = () => {
  const theme = useTheme()
  return (
    <Container>
      <Row>
        <Link to="/">
          <Avatar />
        </Link>
        <Col style={{ marginLeft: theme.margin, justifyContent: "center" }}>
          <p>
            <strong>Mark Johnson</strong> is a web designer, developer, &amp;
            teacher who has been building things on the web for ~
            <TimeSince start={new Date(1996, 5, 1)} /> years.
          </p>
          <Row>
            <IconLink href="https://twitter.com/wmdmark">
              <Icon icon="twitter" />
            </IconLink>
            <IconLink href="http://github.com/wmdmark">
              <Icon icon="github" />
            </IconLink>
            <IconLink href="mailto: wmdmark@gmail.com">
              <Icon icon="mail" />
            </IconLink>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
