import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { formatTimeToRead } from "../utils/helpers"
import Headline from "./Headline"

const PostContainer = styled.article`
  
  /* padding: ${p => p.theme.padding * 2}px; */
  /* border-bottom: 2px solid #000; */
  margin: ${p => p.theme.margin}px;
  /* margin-top: ${p => p.theme.margin * 2}px; */
  /* border-radius: ${p => p.theme.borderRadius}px; */
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, ${p => p.theme.shadowOpacity}); */

  header {
    a {
      text-decoration: none;
      &:hover {
        color: ${p => p.theme.colors.accent};
      }
    }
  }

  h1 {
    margin: 0px;
    font-size: 1.6em;
    margin-bottom: ${p => p.theme.margin}px;
  }

  small {
    font-size: 1em;
    margin: 0px;
    /* text-transform: uppercase; */
    /* font-weight: 600; */
    color: ${p => p.theme.colors.textSecondary};
    margin-bottom: 10px;
  }
`

const Post = ({ post }) => {
  const {
    excerpt,
    timeToRead,
    frontmatter: { title },
    fields: { slug },
  } = post
  return (
    <PostContainer>
      <header>
        <Link to={slug}>
          <Headline>{title}</Headline>
        </Link>
        <small>{formatTimeToRead(timeToRead)}</small>
      </header>
      <p>{excerpt}</p>
    </PostContainer>
  )
}
export default Post
