import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Post from "../components/Post"
import Profile from "../components/Profile"
import styled from "styled-components"

const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* border: 2px solid red; */
`

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMdx
  return (
    <Layout>
      <SEO title="Mark Johnson: Designer, Developer, Teacher" />
      <Profile />
      <Posts>
        {posts.map(({ node: post }, i) => {
          console.log({ post })
          return <Post key={post.fields.slug} post={post} />
        })}
      </Posts>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPosts {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
          }
        }
      }
    }
  }
`

export default IndexPage
